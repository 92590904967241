@import "variables";
@import "mixins";

.rankingsWrapper {
  background: $superLightGray;
  width: 100%;
}

.rankingsContainer {
  max-width: $pc-width;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  margin: 0 auto;
  height: 100%;

  @include sp() {
    flex-direction: column;
    gap: 12px;
  }
}

.ranking {
  width: 50%;

  @include sp() {
    width: 100%;
  }
}
