@import "variables";
@import "mixins";

.container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.title {
  font-size: 25px;
  font-weight: 600;
  text-align: left;
  margin-top: 5px;

  @include sp {
    font-size: 16px;
    padding-left: 8px;
  }

  h2 {
    display: inline-flex;
    align-items: flex-end;
  }

  .pickup {
    @include pc {
      font-size: 20px;
      font-weight: 400;
    }
  }
}

.tabsContainer {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  padding: 0 0 10px;
  margin-bottom: 10px;
  @include sp {
    padding: 0 8px;
  }
}

.tabButton {
  position: relative;
  display: flex;
  width: 100%;
  background: $white;
  border: 1px solid $gray;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  color: $black;
  text-align: center;
  flex-direction: column;
  align-items: center;
  transition:
    color $hover-transition,
    background $hover-transition,
    border $hover-transition;

  @include sp {
    padding: 5px;
  }

  &:hover {
    color: $blue;
    border-color: $blue;
  }

  &.active {
    color: $white;
    background: $blue;
    border-color: $blue;
    font-weight: 600;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 10px solid $blue;
    }

    .floorPlan {
      color: $white;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    @include sp {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .floorPlan {
    font-size: 12px;
    font-weight: 600;
    color: $darkGray;

    @include sp {
      font-size: 9px;
    }
  }
}

.rankContainer {
  margin-top: 15px;
}

.rankList {
  list-style: none;
  border: 1px solid $gray;
  border-bottom: none;

  .rankItem {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid $gray;
    background: $white;

    &:last-child {
      margin-bottom: -1px;
    }

    &:hover {
      opacity: $hover-opacity;
    }

    &.hidden {
      display: none;
    }

    .rankNumber {
      flex-shrink: 0;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      margin: 0 16px;

      @include sp {
        width: 25px;
        font-size: 12px;
      }
    }

    .name {
      flex-grow: 1;
      font-weight: 400;
      font-size: 16px;
      color: $black;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      @include sp {
        font-size: 14px;
      }
    }

    .arrow {
      flex-shrink: 0;
      width: 12px;
      height: 12px;
      background: url("/static/svgs/common/arrow_right.svg") no-repeat center;
      background-size: contain;
    }
  }
}

.moreButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: $blue;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin: 10px auto 0 auto;

  .arrow {
    margin-left: 5px;
    width: 12px;
    height: 12px;
    background: url("/static/svgs/common/arrow_under.svg") no-repeat center;
    background-size: contain;
  }

  .arrowClose {
    transform: rotate(180deg);
  }
}
