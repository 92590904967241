@import "variables";
@import "mixins";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  margin-top: 20px;
  @include pc {
    gap: 18px;
    margin-top: 35px;
  }
}

.text {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  @include pc {
    font-size: 22px;
  }
}

.imageContainer {
  position: relative;
  width: 118px;
  height: 48px;
}

.primaryText {
  color: #05c4d0 !important;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;

  @include pc {
    font-size: 26px;
  }
}

.accentPC {
  color: #05c4d0 !important;

  position: relative;
  &::before {
    color: #05c4d0;

    content: "・";
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8em;
  }
}

.isSmallSP {
  @include sp {
    font-size: 14px;
  }
}
