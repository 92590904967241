@import "variables";
@import "mixins";

.container {
  padding: 0 20px;

  @include pc {
    padding: 25px 0;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px 12px;

  @include pc {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background-color: $white;
  font-size: 13px;
  letter-spacing: 1px;
  box-shadow: 0 4px 15px 0 #00000012;
  border: 1px solid $lightGray;
  border-radius: 5px;

  @include pc {
    width: 177px;
    height: 60px;
    font-size: 16px;
    cursor: pointer;
    transition: opacity $hover-transition;

    &:hover {
      opacity: $hover-opacity;
    }
  }
}
